import { CompositeResourceView, ResourceView } from "Models/resource";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, ColDef, GetRowIdParams, IRowNode, SuppressKeyboardEventParams } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useDeleteCompositeResourceMutation, useGetCompositeResourcesQuery, useUpdateCompositeResourceMutation } from "State/Services/resource";
import { useConfirm } from "material-ui-confirm";
import ActivityResourceSaveCancelCellRenderer from "../Activities/ActivityResourceSaveCancelCellRenderer";
import ResourceUnitCellRenderer from "./ResourceUnitCellRenderer";
import ResourceQuantityEditRenderer from "./ResourceQuantityEditRenderer";
import React from "react";
import ResourceFactorEditCellRenderer from "./ResourceFactorEditCellRenderer";
import { tokens } from "theme";
import { sort } from "Helpers/sort";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import { ResourceAppend } from "./ResourceAppend";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { PanelState } from "Models/panel";

export interface AddEditCompositeResourceProps {
    currentResource: ResourceView;
    estimateId: string;
    onEdit: (state: boolean) => void;
    setCompositeResources: (compositeResources: Array<CompositeResourceView>) => void;
    panelState: PanelState;
}

const AddEditCompositeResource = (props: AddEditCompositeResourceProps) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 32px)', width: '100%' }), []);
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<CompositeResourceView>>(null);
    const [rowData, setRowData] = useState<Array<CompositeResourceView>>([]);
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<CompositeResourceView> | undefined, column?: string }>();
    const { data: storedCompositeResources } = useGetCompositeResourcesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '', resourceId: props?.currentResource.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !props?.currentResource.id });
    const [updateCompResource] = useUpdateCompositeResourceMutation();
    const [deleteCompResource] = useDeleteCompositeResourceMutation();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const confirm = useConfirm();
    const editDisabledRef = useRef<boolean>();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'factor':
                        const resourceFactorInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['factor']
                        });
                        if (resourceFactorInstances && resourceFactorInstances.length > 0 && resourceFactorInstances[0] && typeof (resourceFactorInstances[0] as any).setError === 'function') {
                            (resourceFactorInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [errors])

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true,
        };
    }, []);

    const calculateAmount = useCallback((compositeResource: CompositeResourceView, quantity: number | undefined) => {
        if (!quantity || !compositeResource.rate) return;

        if (compositeResource.factor) {
            return quantity * compositeResource.rate * compositeResource.factor;
        }
        return quantity * compositeResource.rate;
    }, [])

    useEffect(() => {
        if (storedCompositeResources && units) {
            const rows = new Array<CompositeResourceView>();
            storedCompositeResources.forEach((det: CompositeResourceView) => {
                const unit = units?.find((unit) => (unit.id === det.unitId));
                const quantity = (unit?.description === 'PCT' && props.currentResource.basicRate) ? parseFloat(props.currentResource.basicRate.toString()) : det.quantity;
                const amount = calculateAmount(det, quantity);
                rows.push({
                    id: det.id,
                    description: det.description,
                    displayId: det.displayId,
                    selected: true,
                    quantity: quantity,
                    resourceFactor: det.resourceFactor,
                    subCategoryId: det.subCategoryId,
                    amount: amount,
                    rate: det.rate,
                    unitId: det.unitId,
                    factor: det.factor,
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined
                });
            });
            rows.sort(sort);
            setRowData(rows);
            props.setCompositeResources(rows);
        } else {
            setRowData([]);
            props.setCompositeResources([]);
        }
    }, [calculateAmount, props, storedCompositeResources, units])

    const saveCompositeResource = useCallback(async (nodeToSave: IRowNode<CompositeResourceView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<CompositeResourceView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (nodeToSave.data.id && props?.currentResource.id) {
                        await updateCompResource({
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            orgId: user.organizationId,
                            resourceId: props.currentResource.id,
                            compositeId: nodeToSave.data.id,
                            body: {
                                resourceId: props.currentResource.id,
                                compositeId: nodeToSave.data.id,
                                quantity: nodeToSave.data.quantity ?? 0,
                                factor: nodeToSave.data.factor
                            }
                        }).unwrap();
                    }

                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    if (error.data.factor) {
                        setErrors([{ field: 'factor', error: error.data.factor }]);
                    }
                }
                reject(error);
            }
        });
    }, [props.currentResource.id, props.estimateId, updateCompResource, user])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<CompositeResourceView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveCompositeResource(params.node);
            return true;
        }
        return false;
    }, [saveCompositeResource])

    const cancelEditing = useCallback((node: IRowNode<CompositeResourceView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const compositeResource = storedCompositeResources?.find((c) => (c.id === node.data?.id));
            if (compositeResource) {
                const unit = units?.find((unit) => (unit.id === compositeResource.unitId));
                const quantity = (unit?.description === 'PCT' && props.currentResource.basicRate) ? parseFloat(props.currentResource.basicRate.toString()) : compositeResource.quantity;
                const amount = calculateAmount(compositeResource, quantity);
                gridRef.current!.api.applyTransaction({ update: [{ ...compositeResource, amount: amount, unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined }] });
            }
            gridRef.current!.api.stopEditing(true);
            setCurrentEditing(undefined);
        }
    }, [calculateAmount, props.currentResource.basicRate, storedCompositeResources, units])

    const deleteRow = useCallback((node: IRowNode<CompositeResourceView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    await deleteCompResource({
                        orgId: user.organizationId,
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        resourceId: props.currentResource.id,
                        compositeId: node.data.id,
                    });
                }

                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteCompResource, props.currentResource.id, props.estimateId, user])

    const getBaseDefs = useCallback((): Array<ColDef<CompositeResourceView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'selected',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                headerName: 'ID',
                hide: false,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: props.panelState.position.state === 'half' ? 90 : 200,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors.gray[800]}` },
                editable: false,
                suppressKeyboardEvent: saveOnEnter,
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                resizable: true,
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                resizable: true,
                width: 75,
                cellRenderer: ResourceUnitCellRenderer,
                headerName: 'Unit',
                editable: false,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                headerName: 'Quantity',
                width: props.panelState.position.state === 'half' ? 100 : 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: ResourceQuantityEditRenderer,
                resizable: true,
                cellDataType: "number",
                editable: (params) => (!props.currentResource?.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'factor',
                headerName: 'Factor',
                width: 90,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellEditor: ResourceFactorEditCellRenderer,
                resizable: true,
                cellDataType: "number",
                editable: (params) => (!props.currentResource.masterReferenceId),
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                suppressKeyboardEvent: saveOnEnter,
                width: props.panelState.position.state === 'half' ? 100 : 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: false,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'amount',
                suppressKeyboardEvent: saveOnEnter,
                width: props.panelState.position.state === 'half' ? 120 : 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                resizable: true,
                editable: false,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                    background: `repeating-linear-gradient(
                        -45deg,
                        transparent,
                        transparent 4px,
                        transparent 1px,
                        ${colors?.gray[1000]} 7px
                      ),
                      linear-gradient(
                        to bottom,
                        transparent,
                        transparent
                      )`
                }
            },
            {
                field: 'actions',
                resizable: true,
                width: 80,
                headerName: 'Actions',
                editable: false,
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: ActivityResourceSaveCancelCellRenderer,
                cellRendererParams: {
                    save: saveCompositeResource,
                    cancel: cancelEditing,
                    delete: deleteRow,
                    disabled: () => !!props.currentResource.masterReferenceId || editDisabledRef.current
                }
            },
        ]
    }, [cancelEditing, colors.gray, deleteRow, estimate?.CompanyCurrency?.Currency?.minorUnit, props.currentResource.masterReferenceId, props.panelState.position.state, saveCompositeResource, saveOnEnter, settings?.quantityDecimals]);

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const onRowEditingStarted = (event: CellEditingStartedEvent<CompositeResourceView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            if (event.node.data?.unit?.unitDescription === 'PCT') {
                const factorInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'factor']
                });
                if (factorInstances && factorInstances.length > 0 && factorInstances[0] && typeof (factorInstances[0] as any).setFocusOnAdd === 'function') {
                    (factorInstances[0] as any).setFocusOnAdd();
                }
            } else {
                const quantityInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'quantity']
                });
                if (quantityInstances && quantityInstances.length > 0 && quantityInstances[0] && typeof (quantityInstances[0] as any).setFocusOnAdd === 'function') {
                    (quantityInstances[0] as any).setFocusOnAdd();
                }
            }
        }, 100);
    }

    const onRowEditingStopped = useCallback(async (event: CellEditingStoppedEvent) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (isCancelClicked) {
                setIsCancelClicked(false);
                return;
            };

            if (props.currentResource.masterReferenceId || editDisabledRef.current) return;

            if (currentEditing?.node === event.node) {
                return;
            }
            if (event.column.getColId() === 'actions') {
                return;
            }
            if (!currentEditing?.node) {
                if (event.column.isCellEditable(event.node)) {
                    setCurrentEditing({ node: event.node, column: event.column.getColId() });
                } else {
                    setCurrentEditing({ node: event.node, column: undefined });
                }
            } else {
                await saveCompositeResource(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {

        }
    }, [currentEditing?.node, isCancelClicked, props.currentResource.masterReferenceId, saveCompositeResource])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            props.onEdit(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'quantity',
            });
        } else {
            props.onEdit(false);
        }
    }, [currentEditing, props])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getRowId = useCallback(function (params: GetRowIdParams<CompositeResourceView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    return <>{colors && <Box height="calc(100% - 200px)">
        <Box paddingBottom="5px" display="flex" alignItems="center" width="700px">
            <ResourceAppend estimateId={props.estimateId} resourceId={props.currentResource.id} disabled={!!props.currentResource.masterReferenceId || !!editDisabledRef.current} />
        </Box>
        <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
            <AgGridReact<CompositeResourceView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                editType={'fullRow'}
                defaultColDef={defaultColDef}
                suppressClickEdit={true}
                suppressRowClickSelection={true}
                onRowEditingStarted={onRowEditingStarted}
                onRowEditingStopped={onRowEditingStopped}
                onCellClicked={onCellClicked}
                onCellKeyDown={onCellKeyDown}
                getRowId={getRowId}
            />
        </Box>
    </Box>}</>;
}

export default React.memo(AddEditCompositeResource);