import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'
import { configManager } from 'Helpers/config-manager';
import { securityClient } from 'securityClient';

// Create our baseQuery instance
const baseQuery = async (args: any, WebApi: any, extraOptions: any) => {
  const config = configManager.getConfig();
  const baseQuery = fetchBaseQuery({
    baseUrl: `${config?.api.httpProtocol}://${config?.api.url}/api/v1/core/`,
    prepareHeaders: async (headers, { getState }) => {
      const access_token = await securityClient.getAccessTokenSilently()();
      if (access_token) {
        headers.set('Authorization', `Bearer ${access_token}`)
      }
      return headers
    },
  });

  return baseQuery(args, WebApi, extraOptions);
};

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 });
export const api = createApi({
  baseQuery: baseQueryWithRetry,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'User',
    'Organization',
    'Companies',
    'Resources',
    'ResourceDisplayNextId',
    'Estimates',
    'Items',
    'IndirectItems',
    'EstimateCategories',
    'IndirectItemCategories',
    'IndirectItemDetails',
    'Files',
    'FileMetadata',
    'CSIDivisionsV1',
    'CSIDivisionsV2',
    'Analysis',
    'Units',
    'UnitsMaxOrder',
    'ItemDetails',
    'FilteredResources',
    'FilteredActivities',
    'ResourceByDisplayId',
    'ActivityByDisplayId',
    'Activities',
    'Resources',
    'ActivityResources',
    'CompositeResources',
    'StandardItems',
    'StandardItemActivityResources',
    'ResourceCategories',
    'ActivityCategories',
    'StandardItemCategories',
    'ReportEstimates',
    'Margins',
    'Pricing',
    'Settings',
    'ItemCodes',
    'Currencies',
    'CompanyCurrency',
    'CalculatedResource',
    'Estimate',
    'Vendors',
    'MaxVendorId',
    'Tags',
    'SubPackages',
    'SubcontractorPackageVendorFiles',
    'SubcontractorPackageVendors',
    'SubcontractorResourcesActivities',
    'SupplierPackages',
    'SupplierPackageVendorFiles',
    'SupplierPackageVendors',
    'Masters',
    'Users',
    'ItemCalculated',
    'IndirectItemCalculated',
    'Resource',
    'Activity',
    'StandardItem',
    'ProjectPricing',
    'ItemListReport'
  ],
  endpoints: () => ({}),
});