import { Box, Button, useTheme } from "@mui/material";
import { ColDef, Column, ExcelExportParams, ExcelStyle, GridApi, IRowNode, ProcessCellForExportParams, SideBarDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { format } from "date-fns";
import { rounder } from "Helpers/rounder";
import { groupBy, sortBy } from "lodash";
import { EstimateCategory } from "Models/estimate";
import { ItemView } from "Models/item";
import UnitCellRenderer from "Pages/Core/DirectItems/UnitCellRenderer";
import { TableCell, TDocumentDefinitions } from "pdfmake/interfaces";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetCategoriesQuery } from "State/Services/category";
import { useGetEstimateQuery, useGetItemsForEstimateQuery } from "State/Services/estimate";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import pdfMake from 'pdfmake/build/pdfmake';
import fullLogo from 'Images/logo.png';
export interface ProjectPriceListProps {
    estimateId?: string;
    decimals?: number;
}
// Row colors
const HEADER_ROW_COLOR = '#f8f8f8';
const EVEN_ROW_COLOR = '#fcfcfc';
const ODD_ROW_COLOR = '#E6E6E6';

const PDF_INNER_BORDER_COLOR = '#dde2eb';
const PDF_OUTER_BORDER_COLOR = '#babfc7';

export default function ProjectPriceList(props: ProjectPriceListProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: items } = useGetItemsForEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [rowData, setRowData] = useState<Array<ItemView>>();
    const gridStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const { data: categories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const categoriesRef = useRef<Array<EstimateCategory>>();
    categoriesRef.current = categories;
    const [total, setTotal] = useState<number>(0);
    const gridRef = useRef<any>();
    const excelStyles = useMemo((): ExcelStyle[] => {
        return [
            {
                id: 'stringType',
                dataType: 'String',
            },
        ];
    }, []);

    const rateFormatter = useCallback((params: any) => {
        if (props.decimals !== undefined) {
            return rounder(params.value, props.decimals);
        }
    }, [props.decimals])
    const rateFormatterRef = useRef<any>();
    rateFormatterRef.current = rateFormatter;

    const amountFormatter = useCallback((params: any) => {
        if (params.node.group && params.node?.level === 0) {
            let category = categoriesRef.current?.find((cat) => (cat.level === 1 && cat.category === params.node.key));
            if (category?.repetitions) {
                return `${rounder(params.value, estimate?.CompanyCurrency?.Currency.minorUnit ?? 2)} (${rounder(category?.repetitions * params.value, estimate?.CompanyCurrency?.Currency.minorUnit ?? 2)})`
            }
        }
        if (params.node.level === -1) {
            return rounder(total, estimate?.CompanyCurrency?.Currency.minorUnit ?? 2);
        }
        return rounder(params.value, estimate?.CompanyCurrency?.Currency.minorUnit ?? 2);
    }, [estimate?.CompanyCurrency?.Currency.minorUnit, total])
    const amountFormatterRef = useRef<any>();
    amountFormatterRef.current = amountFormatter;

    const quantityFormatter = useCallback((params: any) => {
        if (params.node.group) {
            if (params.node.level === 0) {
                let category = categoriesRef.current?.find((cat) => (cat.level === 1 && cat.category === params.node.key));
                return category?.repetitions;
            } else {
                return '';
            }
        }
        if (params.node.level === -1) {
            return '';
        }
        return params.value;
    }, [])
    const quantityFormatterRef = useRef<any>();
    quantityFormatterRef.current = quantityFormatter;

    const [dataTypeDefinitions] = useState<any>({
        amount: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => amountFormatterRef.current(params)
        },
        quantity: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => quantityFormatterRef.current(params)
        },
        rate: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => rateFormatterRef.current(params)
        }
    });

    const getBaseDefs = useCallback((): Array<ColDef<ItemView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                hide: false,
                cellRenderer: 'agGroupCellRenderer',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 350,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
                showRowGroup: true,
                colSpan: (params: any) => {
                    return 1;
                }
            },
            {
                field: 'itemBoqId',
                width: 100,
                headerName: 'Item',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'remarks',
                width: 300,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                headerName: 'Unit',
                cellRenderer: UnitCellRenderer,
                width: 100,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                aggFunc: 'sum',
                width: 175,
                cellDataType: "quantity",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 200,
                headerName: 'Unit Price',
                cellClass: 'stringType',
                cellDataType: 'rate',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },

            {
                field: 'amount',
                width: 200,
                aggFunc: 'sum',
                enableValue: true,
                cellDataType: 'amount',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            }
        ];
    }, [colors?.gray])

    const [columnDefs, setColumnDefs] = useState<Array<ColDef<ItemView>>>(getBaseDefs());

    useEffect(() => {
        let colDefs = new Array<ColDef>();
        const baseDefs = getBaseDefs();
        switch (estimate?.noOfLevels.toString()) {
            case "0":
                colDefs = [
                    {
                        field: 'page',
                        flex: 1,
                        rowGroup: true,
                        hide: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        resizable: true,
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    ...baseDefs
                ];
                break;
            case "1":
                baseDefs.splice(2, 0,
                    {
                        field: 'category1',
                        headerName: 'Category 1',
                        rowGroup: true,
                        hide: true
                    },
                    {
                        field: 'page',
                        flex: 1,
                        rowGroup: true,
                        hide: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        resizable: true,
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                );
                colDefs = [
                    ...baseDefs,
                ];
                break;
            case "2":
                baseDefs.splice(2, 0,
                    { field: 'category1', headerName: 'Category 1', rowGroup: true, hide: true },
                    { field: 'category2', headerName: 'Category 2', rowGroup: true, hide: true },
                    {
                        field: 'page',
                        flex: 1,
                        rowGroup: true,
                        hide: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        resizable: true,
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                );
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "3":
                baseDefs.splice(2, 0,
                    { field: 'category1', headerName: 'Category 1', rowGroup: true, hide: true },
                    { field: 'category2', headerName: 'Category 2', rowGroup: true, hide: true },
                    { field: 'category3', headerName: 'Category 3', rowGroup: true, hide: true },
                    {
                        field: 'page',
                        flex: 1,
                        rowGroup: true,
                        hide: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        resizable: true,
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    });

                colDefs = [
                    ...baseDefs
                ];
                break;
            case "4":
                baseDefs.splice(2, 0,
                    { field: 'category1', headerName: 'Category 1', rowGroup: true, hide: true },
                    { field: 'category2', headerName: 'Category 2', rowGroup: true, hide: true },
                    { field: 'category3', headerName: 'Category 3', rowGroup: true, hide: true },
                    { field: 'category4', headerName: 'Category 4', rowGroup: true, hide: true },
                    {
                        field: 'page',
                        flex: 1,
                        rowGroup: true,
                        hide: true,
                        menuTabs: ["filterMenuTab", "generalMenuTab"],
                        resizable: true,
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    });
                colDefs = [
                    ...baseDefs
                ];
                break;
            default:
                break;
        }
        setColumnDefs(colDefs);
    }, [colors?.gray, estimate?.noOfLevels, getBaseDefs])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const columnToolPanelParams = useMemo<SideBarDef>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        };
    }, []);

    useEffect(() => {
        if (items && items.length > 0 && props.decimals !== undefined) {
            let currentTotal = 0;
            const itemsToDisplay = new Array<ItemView>();
            const grouped = groupBy(items, (rec) => {
                return rec.category1
            });
            for (const group in grouped) {
                if (Object.prototype.hasOwnProperty.call(grouped, group)) {
                    const items = grouped[group];
                    let category = categories?.find((cat) => (cat.level === 1 && cat.category === group));
                    const total = items.reduce((accumulator, currentValue) => (accumulator + ((parseFloat(currentValue.rate?.toFixed(props.decimals) ?? '') * (currentValue?.quantity ?? 0)) ?? 0)), 0);
                    currentTotal += total * (category?.repetitions ?? 1);
                }
            }
            setTotal(currentTotal);
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                const rounded = item.rate?.toFixed(props.decimals);
                itemsToDisplay.push({
                    category1: item.category1,
                    category2: item.category2,
                    category3: item.category3,
                    category4: item.category4,
                    category1Id: item.category1Id,
                    category2Id: item.category2Id,
                    category3Id: item.category3Id,
                    category4Id: item.category4Id,
                    category1DisplayId: item.category1DisplayId,
                    category2DisplayId: item.category2DisplayId,
                    category3DisplayId: item.category3DisplayId,
                    category4DisplayId: item.category4DisplayId,
                    itemBoqId: item.itemBoqId,
                    page: item.page,
                    amount: parseFloat(rounded ?? '') * (item?.quantity ?? 0),
                    description: item.description,
                    displayId: item.displayId,
                    quantity: item.quantity,
                    remarks: item.remarks,
                    estimatedQuantity: item.estimatedQuantity,
                    rate: item.rate,
                    id: item.id,
                    assignedTo: item.assignedTo,
                    unit: {
                        unitDescription: item.customUnit,
                        unitId: item.unitId
                    },
                    isNew: false,
                    code: item.code
                });
            }
            const sortedItems = sortBy(itemsToDisplay, [
                'category1Id',
                'category2Id',
                'category3Id',
                'category4Id',
                'page',
                'displayId'
            ]);

            setRowData(sortedItems);
        } else {
            setRowData([]);
        }
    }, [categories, props.decimals, items]);

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    const getParams: () => ExcelExportParams = () => ({
        processCellCallback(params: ProcessCellForExportParams): string {
            const value = params.value;
            if (value && params.column.getColId() === 'unit' && 'unitDescription' in value) {
                if (value.unitDescription) {
                    return value.unitDescription;
                } else if (value.unitId) {
                    const unit = units?.find((u) => (u.id === value.unitId));
                    if (unit) {
                        return unit.description;
                    }
                }
                return value.unitDescription;
            }
            if (value && params.column.getColId() === 'rate' && props.decimals !== undefined) {
                return rounder(params.value, props.decimals).toString();
            }
            if (value && params.column.getColId() === 'amount' && props.decimals !== undefined) {
                return rounder(params.value, estimate?.CompanyCurrency?.Currency?.minorUnit ?? 2);
            }
            return value;
        },
    });

    const getHeaderToExport = useCallback((gridApi: GridApi<ItemView>) => {
        const columns = gridApi.getAllDisplayedColumns();
        return columns.map((column) => {
            const { field, sort } = column.getColDef();
            let headerCell = { text: '', bold: false, margin: [] as Array<number>, alignment: '' };
            if (field) {
                // Enables export when row grouping
                const headerName = column.getColDef().headerName ?? field;
                const headerNameUppercase =
                    headerName[0].toUpperCase() + headerName.slice(1);
                const cellStyle = column.getColDef().cellStyle;
                headerCell = {
                    text: headerNameUppercase + (sort ? ` (${sort})` : ''),
                    alignment: ((cellStyle && 'textAlign' in cellStyle) && cellStyle?.textAlign) ? cellStyle?.textAlign as string : 'left',
                    // styles
                    bold: true,
                    margin: [0, 12, 0, 0],
                };
            }

            return headerCell;
        });
    }, []);

    const getCellToExport = useCallback((column: Column<ItemView>, node: IRowNode<ItemView>): TableCell | undefined => {
        let cellText = gridRef.current!.api.getValue(column, node);
        switch (column.getColDef().cellDataType) {
            case "amount":
                cellText = rounder(cellText, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)
                break;
            default:
                break;
        }
        const cellStyle = column.getColDef().cellStyle;
        // if (node.group && (column.getColId() === 'itemBoqId' || column.getColId() === 'description' || column.getColId() === 'remarks' || column.getColId() === 'unit')) {
        //     return;
        // }

        return {
            text: cellText,
            colSpan: (node.group && column.getColId() === 'displayId') ? 5 : 1,
            alignment: ((cellStyle && 'textAlign' in cellStyle) && cellStyle?.textAlign) ?? 'left',
            fontSize: 10,
            // styles
            ...column.getColDef().cellStyle,
        };
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit]);

    const getRowsToExport = useCallback((gridApi: GridApi<ItemView>) => {
        const columns = gridApi.getAllDisplayedColumns();
        const rowsToExport = new Array<TableCell[]>();

        gridApi.forEachNodeAfterFilterAndSort((node) => {
            let row = new Array<TableCell>();
            for (let i = 0; i < columns.length; i++) {
                const column = columns[i];
                const cells = getCellToExport(column, node);
                if (cells)
                    row.push(cells);
            }
            if (row.length > 0) {
                rowsToExport.push([...row]);
            }
        });

        return rowsToExport;
    }, [getCellToExport]);

    const createLayout = useCallback((numberOfHeaderRows: number) => ({
        fillColor: (rowIndex: number) => {
            if (rowIndex < numberOfHeaderRows) {
                return HEADER_ROW_COLOR;
            }
            return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
        },
        paddingTop: function (rowIndex: number, node: any) {
            return rowIndex === 0 ? 2 : 8;
        },
        paddingBottom: function (rowIndex: number, node: any) {
            return rowIndex === 0 ? 2 : 8;
        },
        //vLineHeight not used here.
        vLineWidth: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.widths.length ? 1 : 0,
        hLineColor: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.body.length
                ? PDF_OUTER_BORDER_COLOR
                : PDF_INNER_BORDER_COLOR,
        vLineColor: (rowIndex: number, node: any) =>
            rowIndex === 0 || rowIndex === node.table.widths.length
                ? PDF_OUTER_BORDER_COLOR
                : PDF_INNER_BORDER_COLOR,
    }), []);

    const convertImageToBase64 = useCallback(async () => {
        return new Promise((resolve, reject) => {
            try {
                const img = new Image();
                img.crossOrigin = 'anonymous';
                img.src = fullLogo;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.naturalWidth;
                    canvas.height = img.naturalHeight;
                    const ctx = canvas.getContext('2d');
                    ctx!.fillStyle = 'white';
                    ctx!.fillRect(0, 0, canvas.width, canvas.height);
                    ctx!.drawImage(img, 0, 0);
                    const base64data = canvas.toDataURL('image/png', 1.0);
                    resolve(base64data);
                };
            } catch (error) {
                reject(error);
            }
        });
    }, [])

    const getDocument = useCallback(async (gridApi: GridApi<ItemView>): Promise<TDocumentDefinitions> => {
        const headerRow = getHeaderToExport(gridApi);
        const rows = getRowsToExport(gridApi);
        const logo = await convertImageToBase64();
        return {
            pageOrientation: 'portrait' as any, // can also be 'portrait'
            pageMargins: [10, 20] as any,
            content: [
                {
                    style: 'header',
                    columns: [
                        {
                            image: logo as string,
                            width: 196,
                            height: 60

                        },
                    ]
                },
                {
                    columns: [
                        {
                            text: `Project: ${estimate?.name}`,
                            style: 'headerInfo'
                        },
                        {
                            text: `Date: ${format(new Date().getTime(), 'MM/dd/yyyy')}`,
                            style: 'headerDate'
                        }
                    ]
                },
                {
                    table: {
                        // the number of header rows
                        headerRows: 1,

                        // the width of each column, can be an array of widths
                        widths: [20, 30, '*', 50, 30, 50, 60, 60],
                        // widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                        // all the rows to display, including the header rows
                        body: [headerRow, ...rows],

                        // Header row is 40px, other rows are 15px
                        heights: (rowIndex: number) => (rowIndex === 0 ? 40 : 15),
                    },
                    layout: createLayout(1),
                },
            ],
            styles: {
                headerDate: {
                    alignment: 'right' as any,
                },
                header: {
                    marginBottom: 20,
                },
                alignCenter:
                {
                    alignment: 'center'
                },
                headerInfo: {
                    marginTop: 5,
                    marginBottom: 15,
                    fontSize: 12,
                    alignment: 'left'
                }
            },
            defaultStyle: {
                columnGap: 20
            }

        };
    }, [convertImageToBase64, createLayout, estimate?.name, getHeaderToExport, getRowsToExport]);

    const exportToPDF = useCallback(async () => {
        if (gridRef.current!.api) {
            const doc = await getDocument(gridRef.current!.api);
            pdfMake.createPdf(doc).download();
        }

    }, [getDocument]);

    return <Box display="flex" flexDirection="column" width="100%" height="100%">
        <Box display="flex" justifyContent="flex-end" gap="20px" marginBottom="20px">
            <Button variant="outlined" onClick={exportToPDF}>Download PDF</Button>
        </Box>
        <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<ItemView>
                dataTypeDefinitions={dataTypeDefinitions}
                rowData={rowData}
                ref={gridRef}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={columnToolPanelParams}
                groupDisplayType={'custom'}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={-1}
                excelStyles={excelStyles}
                suppressClickEdit={true}
                getRowStyle={getRowStyle}
                groupIncludeTotalFooter={true}
                defaultExcelExportParams={getParams()}
            />
        </Box>
    </Box>;
}