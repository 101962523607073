import { Box, useTheme } from "@mui/material";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";

export interface AffectedResource{
    resourceId: string;
    description: string;
    displayId: string;
}
export interface AffectedResourcesProps {
    resources: Array<AffectedResource>;
}

export default function AffectedResources(props: AffectedResourcesProps) {
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 45px)', width: '100%', overflow: 'auto' }), []);
    const [rowData, setRowData] = useState<Array<AffectedResource>>([]);
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    
    useEffect(() =>{
        if (props.resources && props.resources.length>0){
            setRowData(props.resources);
        }
    }, [props.resources])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true,
        };
    }, []);

    const getBaseDefs = useCallback((): Array<ColDef<AffectedResource>> => {
        return [
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                width: 80,
                headerName: 'ID',
                hide: false,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors.gray[800]}` }
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            }
        ]
    }, [colors.gray]);

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    return <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
        <AgGridReact<AffectedResource>
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressClickEdit={true}
            suppressRowClickSelection={true}
        />
    </Box>
}