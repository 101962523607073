import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rounder } from "Helpers/rounder";
import { ItemListReport, ItemListReportView } from "Models/item-list-report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import UnitCellRenderer from "./UnitCellRenderer";
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import { groupBy } from "lodash";

export interface ItemBreakdownByResourceCategoryLevel3Props {
    estimateId: string | undefined;
    itemBreakDownReport: {
        results: Array<ItemListReport>;
        totalCount: number;
        categories: Array<string>;
    }
}

export default function ItemBreakdownByResourceCategoryLevel3(props: ItemBreakdownByResourceCategoryLevel3Props) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<ItemListReportView>>(null);
    const [rowData, setRowData] = useState<Array<ItemListReportView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId })

    useEffect(() => {
        if (props.itemBreakDownReport && props.itemBreakDownReport.results.length > 0) {
            const grouped = groupBy(props.itemBreakDownReport.results, (result) => {
                return result.itemId;
            });
            const rowData = props.itemBreakDownReport.results.map((result) => ({
                ...result,
                unit: {
                    unitDescription: result.customUnit,
                    unitId: result.unitId
                },
                id: uuidv4()
            })) as Array<ItemListReportView>;
            rowData.forEach((result) => {
                // Check if this result is the root item (exactly one hierarchy entry)
                if (result.hierarchy.length === 1) {
                    // Calculate the category sums for this root item
                    props.itemBreakDownReport.categories.forEach((category) => {
                        const group = grouped[result.itemId];
                        if (group) {
                            // Sum all category values in the group
                            const categorySum = group.reduce((sum, item) => sum + (item[category] || 0), 0);
                            // Update the category value in the root item
                            result[category] = categorySum;
                        }
                    });
                }
            });
            setRowData(rowData);
        }
    }, [props.itemBreakDownReport])

    const getBaseDefs = useCallback((): Array<ColDef<ItemListReportView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'subItemId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'itemSubcontractorId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'description',
                width: 300,
                pinned: true,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                pinned: true,
                cellRenderer: UnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalQuantity',
                headerName: 'Quantity',
                pinned: true,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 160,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 160,
                pinned: true,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalAmount',
                headerName: 'Amount',
                pinned: true,
                aggFunc: params => {
                    if (params.rowNode.level >= 0) {
                        return params.data.totalAmount;
                    }
                    let total = 0;
                    params.values.forEach(value => total += value);
                    return total;
                },
                width: 160,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
        ];
    }, [colors?.gray, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit])

    const [columnDefs, setColumnDefs] = useState<ColDef[]>();

    const setupGrid = useCallback(async () => {
        const cols = getBaseDefs();
        props.itemBreakDownReport.categories.forEach((categoryId) => {
            cols.push({
                field: categoryId as any,
                headerValueGetter: () => {
                    return (categoryId==='Subcontractor')?'Subcontractor': (storedResourceCategories?.find(r => r.id === categoryId)?.description ?? '');
                },
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            });
        });
        setColumnDefs(cols);
    }, [colors?.gray, estimate?.CompanyCurrency?.Currency?.minorUnit, getBaseDefs, props.itemBreakDownReport.categories, storedResourceCategories])

    useEffect(() => {
        setupGrid();
    }, [setupGrid])

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            field: 'displayId',
            resizable: true,
            pinned: true,
            headerName: 'ID',
            cellDataType: "text",
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            cellRendererParams: {
                suppressCount: true,
            }
        };
    }, [colors?.gray]);

    const getRowId = useCallback(function (params: GetRowIdParams<ItemListReportView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
        <AgGridReact<ItemListReportView>
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            autoGroupColumnDef={autoGroupColumnDef}
            getRowId={getRowId}
            treeData={true}
            paginationAutoPageSize={true}
            pagination={true}
            groupDefaultExpanded={-1}
            groupIncludeTotalFooter={true}
            getDataPath={(data: any) => (data.hierarchy)}
        />
    </Box>
}