import { ActivityView } from "Models/activity";
import { useLazyGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { Box, Button, IconButton, Link, Modal, TextField, Typography, useTheme } from "@mui/material";
import { BaseResource, ResourceView } from "Models/resource";
import { ColDef, IRowNode } from "ag-grid-enterprise";
import ResourceUnitCellRenderer from "./ResourceUnitCellRenderer";
import ResourceActionsCellRenderer from "./ResourceActionsCellRenderer";
import { AgGridReact } from "ag-grid-react";
import AddEditResource from "./AddEditResource";
import { useBulkRefreshCurrenciesMutation, useBulkUpdateFactorMutation, useCreateResourceMutation, useDeleteResourceMutation, useGetResourceRowsMutation, useLazyGetResourceByIdQuery, useUpdateResourceMutation } from "State/Services/resource";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import AddEditResourceCategory from "./AddEditResourceCategory";
import { useLazyGetResourceCategoriesQuery } from "State/Services/resource-category";
import ResourceCategoryFilters, { FilterSettings } from "./ResourceCategoryFilters";
import { v4 as uuidv4 } from 'uuid';
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import ResourceListFactorHeader from "./ResourceListFactorHeader";
import { useConfirm } from "material-ui-confirm";
import ResourceBasicRateHeader from "./ResourceBasicRateHeader";
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, FilterChangedEvent, GetRowIdParams, GridReadyEvent, ICellRendererParams, IServerSideDatasource, IServerSideGetRowsParams, PaginationChangedEvent, SelectionChangedEvent, SortChangedEvent, SuppressKeyboardEventParams } from "ag-grid-community";
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import SupplierQuotes from "./SupplierQuotes";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ResourceListIdEditRenderer from "./ResourceListIdEditRenderer";
import ResourceListDescriptionEditCellRenderer from "./ResourceListDescriptionEditCellRenderer";
import ResourceListUnitEditCellRenderer from "./ResourceListUnitEditCellRenderer";
import ResourceListCategoryEditCellRenderer from "./ResourceListCategoryEditCellRenderer";
import { PanelState } from "Models/panel";
import ResourceListSubCategoryEditCellRenderer from "./ResourceListSubCategoryEditCellRenderer";
import ResourceListNumberEditCellRenderer from "./ResourceListNumberEditCellRenderer";
import { ResourceCategory, ResourceSubCategory } from "Models/resource-category";
import { ServerError } from "Models/error-info";
import { Unit } from "Models/unit";
import { flushSync } from "react-dom";
import SplitButton, { MenuOption } from "Components/SplitButton";

export interface ResourceProps {
    resourceId?: string;
    estimateId: string | undefined;
    onDrop?: (activity: ActivityView) => void;
    panelState: PanelState;
}

const factorModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "15%",
    height: "20%",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"
};

export default function ResourceList(props: ResourceProps) {
    const confirm = useConfirm();
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 48px)', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const [bulkUpdateFactor] = useBulkUpdateFactorMutation();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [getUnits] = useLazyGetUnitsQuery();
    const [units, setUnits] = useState<Array<Unit>>();
    const [showAdd, setShowAdd] = useState(false);
    const [editResource, setEditResource] = useState<ResourceView>();
    const gridRef = useRef<AgGridReact<ResourceView>>(null);
    const [deleteResource] = useDeleteResourceMutation();
    const [getResourceCategories] = useLazyGetResourceCategoriesQuery();
    const [seed, setSeed] = useState<number>();
    const keyToUse = useMemo(() => { return seed }, [seed]);
    const [factorValue, setFactorValue] = useState<number | undefined>();
    const [openFactorModal, setOpenFactorModal] = useState(false);
    const [refreshCurrencyRates] = useBulkRefreshCurrenciesMutation();
    const [selectedRows, setSelectedRows] = useState<Array<ResourceView>>([]);
    const [openSupplierModal, setOpenSupplierModal] = useState(false);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [getResourceRows] = useGetResourceRowsMutation();
    const [getResourceById] = useLazyGetResourceByIdQuery();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<ResourceView> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [currentResourceRows, setCurrentResourceRows] = useState<Array<BaseResource>>([]);
    const [resourceCategories, setResourceCategories] = useState<Array<ResourceCategory>>([]);
    const [saveResource] = useCreateResourceMutation();
    const [updateResource] = useUpdateResourceMutation();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [unitRef, setUnitRef] = useState<MutableRefObject<any> | undefined>();
    const [categoryRef, setCategoryRef] = useState<MutableRefObject<any> | undefined>();
    const [subCategoryRef, setSubCategoryRef] = useState<MutableRefObject<any> | undefined>();
    const [addMenuItems] = useState<Array<MenuOption>>([{ option: 'New inline', disabled: false }, { option: 'New in form', disabled: false }]);

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'name':
                        const nameInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['name']
                        });
                        if (nameInstances && nameInstances.length > 0 && nameInstances[0] && typeof (nameInstances[0] as any).setError === 'function') {
                            (nameInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'description':
                        const descriptionInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['description']
                        });
                        if (descriptionInstances && descriptionInstances.length > 0 && descriptionInstances[0] && typeof (descriptionInstances[0] as any).setError === 'function') {
                            (descriptionInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'resourceFactor':
                        const resourceFactorInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['resourceFactor']
                        });
                        if (resourceFactorInstances && resourceFactorInstances.length > 0 && resourceFactorInstances[0] && typeof (resourceFactorInstances[0] as any).setError === 'function') {
                            (resourceFactorInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'displayId':
                        const idInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (idInstances && idInstances.length > 0 && idInstances[0] && typeof (idInstances[0] as any).setError === 'function') {
                            (idInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'unit':
                        const unitInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['unit']
                        });
                        if (unitInstances && unitInstances.length > 0 && unitInstances[0] && typeof (unitInstances[0] as any).setError === 'function') {
                            (unitInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [errors])

    const getResources = useCallback(async (params: IServerSideGetRowsParams<ResourceView>) => {
        if (user) {
            let storedResourceCategories = new Array<ResourceCategory>();
            let storedUnits = new Array<Unit>();
            const response = await getResourceRows({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                body: { ...params.request }
            }).unwrap();
            if (resourceCategories && resourceCategories.length > 0) {
                storedResourceCategories = resourceCategories;
            } else {
                storedResourceCategories = await getResourceCategories({
                    companyId: user.companyId ?? '',
                    organizationId: user.organizationId ?? '',
                    estimateId: props.estimateId ?? '',
                }, true).unwrap();
                setResourceCategories(storedResourceCategories);
            }
            if (units && units.length > 0) {
                storedUnits = units
            } else {
                storedUnits = await getUnits({
                    companyId: user.companyId ?? '',
                    organizationId: user.organizationId ?? ''
                }, true).unwrap();
                setUnits(storedUnits);
            }

            if (params?.request) {
                const rows = new Array<ResourceView>();
                setCurrentResourceRows(response.results);
                response.results.forEach((res) => {
                    const unit = storedUnits?.find((unit) => (unit.id === res.unitId));
                    let category = undefined;
                    if (res.categoryId && storedResourceCategories) {
                        category = storedResourceCategories.find((cat) => (cat.id === res.categoryId));
                    }
                    let subCategory = undefined;
                    if (res.subCategoryId && storedResourceCategories) {
                        subCategory = category?.resourceSubCategories?.find((cat) => (cat.id === res.subCategoryId));
                    }
                    rows.push({
                        id: res.id,
                        description: res.description,
                        displayId: res.displayId,
                        rate: res.rate,
                        basicRate: res.basicRate,
                        subCategoryId: res.subCategoryId,
                        isComposite: res.isComposite,
                        resourceFactor: res.resourceFactor,
                        unitId: res.unitId,
                        categoryId: res.categoryId,
                        isSupplierRate: res.isSupplierRate,
                        supplierName: res.supplierName,
                        unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                        type: "resource",
                        categoryDescription: category?.description,
                        subCategoryDescription: subCategory?.description,
                        canDelete: res.canDelete,
                        masterReferenceId: res.masterReferenceId,
                        isNew: false
                    });
                });

                params.success({
                    rowData: rows,
                    rowCount: response.totalCount
                });
            }
        }
    }, [getResourceCategories, getResourceRows, getUnits, props.estimateId, resourceCategories, units, user])
    const getResourcesRef = useRef<(params: IServerSideGetRowsParams<ResourceView>) => void>(() => (null));
    getResourcesRef.current = getResources;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getResourcesRef.current(params),
        };
    }, []);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleOpenFactorModal = useCallback(() => {
        setOpenFactorModal(true);
    }, []);

    const handleOpenSupplierModal = () => {
        setOpenSupplierModal(true);
    };
    const handleCloseSupplierModal = () => {
        setOpenSupplierModal(false);
    };

    const handleCloseFactorModal = useCallback(async (save: boolean) => {
        if (save && factorValue) {
            const filterModel = gridRef.current!.api.getFilterModel();
            await bulkUpdateFactor({
                companyId: user?.companyId,
                orgId: user?.organizationId,
                estimateId: props.estimateId,
                body: {
                    factor: factorValue,
                    filterModel: filterModel
                }
            });
            setFactorValue(undefined);
            gridRef.current!.api.onFilterChanged();
        }
        setOpenFactorModal(false);
    }, [bulkUpdateFactor, factorValue, props.estimateId, user?.companyId, user?.organizationId]);

    const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
    const handleOpenCategoriesModal = useCallback(() => {
        setOpenCategoriesModal(true);
    }, []);

    const handleCloseCategoriesModal = useCallback(async () => {
        setOpenCategoriesModal(false);
    }, []);

    const showHideCols = useCallback(() => {
        if (gridRef.current?.api) {
            gridRef.current!.api.setColumnsVisible(["isComposite", "categoryDescription", "subCategoryDescription", "resourceFactor"], props.panelState.position.state !== "half");
        }
    }, [props.panelState.position.state])

    useEffect(() => {
        if (gridRef.current?.api) {
            showHideCols();
        }
    }, [showHideCols])

    const resetAndAddNew = useCallback((subCategoryId: string | undefined) => {
        setSeed(Math.random());
        setEditResource({
            subCategoryId: subCategoryId
        });
    }, [])

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
        showHideCols();
    }, [createServerSideDatasource, showHideCols]);

    const getRowId = useCallback(function (params: GetRowIdParams<ResourceView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const switchToEdit = useCallback((resource: ResourceView | undefined) => {
        if (resource) {
            setEditResource(resource);
            setShowAdd(true);
        }
    }, [])

    const navigateToResource = useCallback(() => {
        if (props.resourceId && user) {
            getResourceById({
                companyId: user.companyId ?? '',
                estimateId: props.estimateId ?? '',
                organizationId: user.organizationId,
                resourceId: props.resourceId
            }).then((response) => {
                setSeed(Math.random());
                switchToEdit(response.data);
            })
        }
    }, [getResourceById, props.estimateId, props.resourceId, switchToEdit, user])

    useEffect(() => {
        navigateToResource();
    }, [props.resourceId, switchToEdit, props.estimateId, user, getResourceById, navigateToResource])

    const edit = useCallback((node: IRowNode<ResourceView>) => {
        switchToEdit(node.data);
    }, [switchToEdit])

    const deleteRow = useCallback((node: IRowNode<ActivityView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    await deleteResource({
                        resourceId: node.data.id,
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId
                    });
                    gridRef.current!.api.onFilterChanged();
                }
                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteResource, props.estimateId, user]);
    const deleteRowRef = useRef<any>();
    deleteRowRef.current = deleteRow;

    const clearFactor = useCallback(async () => {
        try {
            await confirm({ description: `This will clear the factors for all resources. Do you want to continue?`, title: 'Clear factor confirmation', dialogProps: { disableRestoreFocus: true } });
            const filterModel = gridRef.current!.api.getFilterModel();
            await bulkUpdateFactor({
                companyId: user?.companyId,
                orgId: user?.organizationId,
                estimateId: props.estimateId,
                body: {
                    factor: null,
                    filterModel: filterModel
                }
            });
            gridRef.current!.api.onFilterChanged();
        } catch (error) {

        }
    }, [bulkUpdateFactor, confirm, props.estimateId, user?.companyId, user?.organizationId])

    const refreshBasicRate = useCallback(async () => {
        try {
            await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: `Are you sure? This will apply the company-defined currency exchange rates to all resources that have a Basic Rate in a currency different from the estimate currency.`, title: 'Currency Rate Confirmation' });
            await refreshCurrencyRates({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                orgId: user?.organizationId,
            });
        } catch (error) {
            // Do nothing. User cancelled.
        }
    }, [confirm, props.estimateId, refreshCurrencyRates, user?.companyId, user?.organizationId])

    const cancelEditing = useCallback((node: IRowNode<ResourceView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const resource = currentResourceRows?.find((c) => (c.id === node.data?.id));
            if (resource) {
                const unit = units?.find((unit) => (unit.id === resource.unitId));
                let category = undefined;
                if (resource.categoryId && resourceCategories) {
                    category = resourceCategories.find((cat) => (cat.id === resource.categoryId));
                }
                let subCategory = undefined;
                if (resource.subCategoryId && resourceCategories) {
                    subCategory = category?.resourceSubCategories?.find((cat) => (cat.id === resource.subCategoryId));
                }
                node.updateData({
                    ...resource,
                    type: 'resource',
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    categoryDescription: category?.description,
                    subCategoryDescription: subCategory?.description,
                });
            }
            gridRef.current!.api.stopEditing(true);
            if (node.data.isNew) {
                gridRef.current!.api.applyServerSideTransaction({ remove: [node.data] });
            }

            setCurrentEditing(undefined);
        }
    }, [currentResourceRows, resourceCategories, units])
    const cancelEditingRef = useRef<any>();
    cancelEditingRef.current = cancelEditing;

    const saveRow = useCallback(async (nodeToSave: IRowNode<ResourceView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ResourceView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (!nodeToSave.data.displayId) {
                        const error: ServerError = { data: { displayId: 'ID is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data.description) {
                        const error: ServerError = { data: { description: 'Description is required' } };
                        throw error;
                    }
                    if (nodeToSave.data.id) {
                        if (nodeToSave.data.isNew) {
                            await saveResource({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    subCategoryId: nodeToSave.data.subCategoryId,
                                    basicRate: parseFloat(nodeToSave.data.basicRate?.toString() ?? '0'),
                                    description: nodeToSave.data.description,
                                    displayId: nodeToSave.data.displayId,
                                    unitId: nodeToSave.data.unit?.unitId,
                                    isSupplierRate: false,
                                    resourceFactor: nodeToSave.data.resourceFactor
                                }
                            }).unwrap();
                        } else {
                            await updateResource({
                                resourceId: nodeToSave.data?.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    subCategoryId: nodeToSave.data.subCategoryId,
                                    id: nodeToSave.data.id,
                                    basicRate: parseFloat(nodeToSave.data.basicRate?.toString() ?? '0'),
                                    description: nodeToSave.data.description,
                                    displayId: nodeToSave.data.displayId,
                                    unitId: nodeToSave.data.unit?.unitId,
                                    isComposite: nodeToSave.data.isComposite,
                                    isSupplierRate: nodeToSave.data?.isSupplierRate ?? false,
                                    resourceFactor: nodeToSave.data.resourceFactor
                                }
                            }).unwrap();
                        }
                        nodeToSave.setDataValue('isNew', false);
                        gridRef.current!.api.refreshServerSide();
                    }
                    resolve();

                    if (toEditAfterSave?.nodeToEditAfterSave) {
                        if (toEditAfterSave?.nodeToEditAfterSave?.data?.masterReferenceId && toEditAfterSave?.column !== 'basicRate') {
                            setCurrentEditing(undefined);
                            return;
                        }
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    if (error.data.displayId) {
                        setErrors([{ field: 'displayId', error: error.data.displayId }]);
                    } else if (error.data.description) {
                        setErrors([{ field: 'description', error: error.data.description }]);
                    } else if (error.data.name) {
                        setErrors([{ field: 'name', error: error.data.name }]);
                    } else if (error?.data?.unitId) {
                        setErrors([{ field: 'unit', error: error?.data?.unitId }]);
                    } else if (error?.data?.resourceFactor) {
                        setErrors([{ field: 'resourceFactor', error: error?.data?.resourceFactor }]);
                    }
                }
                reject(error);
            }
        });
    }, [props.estimateId, saveResource, updateResource, user])
    const saveEditingRef = useRef<any>();
    saveEditingRef.current = saveRow;

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<ResourceView>) => {
        if (params.event.key === 'Enter' && params.node) {
            if (params.event.target === unitRef?.current ||
                params.event.target === categoryRef?.current ||
                params.event.target === subCategoryRef?.current) {
                return true;
            }
            if (params.event.target instanceof Element){
                const actionAttrib = params.event.target.getAttribute('data-button-action');
                if (actionAttrib){
                    if (actionAttrib==='cancel'){
                        cancelEditing(params.node);
                        setIsCancelClicked(false);
                        return;
                    }
                }
            }

            params.event.stopPropagation();
            const save = async () => {
                try {
                    await saveRow(params.node);
                } catch (error) {

                }
            }
            save();
        }
        return true;
    }, [cancelEditing, categoryRef, saveRow, subCategoryRef, unitRef])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveOnEnterRef = useRef<any>();
    saveOnEnterRef.current = saveOnEnter;

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getBaseDefs = useCallback((): Array<ColDef<ResourceView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'categoryId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'subCategoryId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'isNew',
                cellDataType: 'boolean',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                resizable: true,
                headerName: 'ID',
                hide: false,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                sort: 'asc',
                cellEditor: ResourceListIdEditRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                editable: (params) => {
                    return !params.data?.masterReferenceId && !editDisabledRef.current;
                },
                headerCheckboxSelection: true,
                checkboxSelection: true,
                dndSource: (props.onDrop ? true : false),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                showRowGroup: true,
                width: 120,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'description',
                flex: 1,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: ResourceListDescriptionEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellRenderer: (params: ICellRendererParams<ResourceView>) => (<>{params.data && <Link component="button" onClick={() => edit(params.node)} variant="body2">{params.data.description}</Link>}</>),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                filter: 'agTextColumnFilter',
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'categoryDescription',
                headerName: 'Category',
                width: 200,
                cellEditor: ResourceListCategoryEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    setRef: setCategoryRef,
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                filter: 'agTextColumnFilter',
                hide: true,
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'subCategoryDescription',
                headerName: 'Subcategory',
                width: 200,
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: ResourceListSubCategoryEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    setRef: setSubCategoryRef,
                },
                filter: 'agTextColumnFilter',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                hide: true,
                resizable: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                width: 75,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: ResourceListUnitEditCellRenderer,
                cellEditorParams: {
                    setRef: setUnitRef,
                },
                cellRenderer: ResourceUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'isComposite',
                width: 65,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellRenderer: (params: ICellRendererParams<ResourceView>) => {
                    if (params.data?.isComposite) {
                        return <Box display="flex" width="100%" height="100%" justifyContent="center" alignItems="center"><CheckOutlinedIcon /></Box>
                    }
                    return '';
                },
                resizable: true,
                hide: true,
                editable: false,
                cellDataType: "boolean",
                headerName: 'Composite',
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'basicRate',
                width: 175,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                editable: (params) => (!editDisabledRef.current),
                cellEditor: ResourceListNumberEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Basic Rate'
                },
                headerComponent: ResourceBasicRateHeader,
                headerComponentParams: {
                    refresh: refreshBasicRate,
                    estimateId: props.estimateId
                },
                headerName: 'Basic Rate',
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'resourceFactor',
                width: 90,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                hide: true,
                editable: (params) => (!editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: ResourceListNumberEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Factor'
                },
                headerComponent: ResourceListFactorHeader,
                headerComponentParams: {
                    factorSet: handleOpenFactorModal,
                    factorClear: clearFactor,
                    estimateId: props.estimateId
                },
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                headerName: 'Factor',
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                sortable: false,
                editable: false,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                resizable: true,
                width: 80,
                sortable: false,
                editable: false,
                headerName: 'Actions',
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: ResourceActionsCellRenderer,
                cellRendererParams: {
                    edit: edit,
                    delete: (node: IRowNode<ResourceView>) => deleteRowRef.current(node),
                    save: (node: IRowNode<ResourceView>) => saveEditingRef.current(node),
                    cancel: (node: IRowNode<ResourceView>) => cancelEditingRef.current(node),
                    disabled: () => deleteDisabledRef.current
                }
            },
        ];
    }, [clearFactor, colors?.gray, edit, estimate?.CompanyCurrency?.Currency?.minorUnit, handleOpenFactorModal, props.estimateId, props.onDrop, refreshBasicRate, settings?.quantityDecimals])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const gridDragOver = useCallback((event: any) => {
        const dragSupported = event.dataTransfer.types.length;
        if (dragSupported) {
            event.dataTransfer.dropEffect = 'copy';
            event.preventDefault();
        }
    }, []);

    const addNewResourceInForm = useCallback(() => {
        setShowAdd(showAdd => !showAdd);
        setEditResource(undefined);
    }, [])

    const filterChanged = useCallback(async (filterSettings: FilterSettings) => {
        let filters = {};
        if (filterSettings.categoryDescription) {
            const catFilter = {
                categoryDescription: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.categoryDescription
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.subCategoryDescription) {
            const subCatFilter = {
                subCategoryDescription: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.subCategoryDescription
                }
            };
            filters = { ...filters, ...subCatFilter };
        }
        if (filterSettings.searchText) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.searchText
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])

    const backToList = useCallback(() => {
        setShowAdd(showAdd => !showAdd);
    }, [])

    const onSelectionChanged = (event: SelectionChangedEvent<ResourceView>) => {
        const selectedRows = new Array<ResourceView>();
        event.api.forEachNode(node => {
            if (node.data && node.isSelected()) {
                selectedRows.push(node.data);
            }
        });
        setSelectedRows(selectedRows);
    }

    const getQuotes = useCallback(() => {
        if (selectedRows && selectedRows.length > 0) {
            setOpenSupplierModal(true);
        }
    }, [selectedRows])

    const onRowEditingStarted = useCallback((event: CellEditingStartedEvent<ResourceView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            if (event.node.data?.unit?.unitDescription === 'PCT') {
                const factorInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'displayId']
                });
                if (factorInstances && factorInstances.length > 0 && factorInstances[0] && typeof (factorInstances[0] as any).setFocusOnAdd === 'function') {
                    (factorInstances[0] as any).setFocusOnAdd();
                }
            } else {
                const quantityInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'displayId']
                });
                if (quantityInstances && quantityInstances.length > 0 && quantityInstances[0] && typeof (quantityInstances[0] as any).setFocusOnAdd === 'function') {
                    (quantityInstances[0] as any).setFocusOnAdd();
                }
            }
        }, 100);
    }, [currentEditing?.column])

    const onRowEditingStopped = useCallback(async (event: CellEditingStoppedEvent<ResourceView>) => {
        event.api.refreshCells({
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<ResourceView>) => {
        try {
            if (isCancelClicked) {
                setIsCancelClicked(false);
                return;
            };

            if (editDisabledRef.current) {
                setCurrentEditing(undefined);
                return;
            };

            if (currentEditing?.node === event.node) {
                return;
            }
            if (event.column.getColId() === 'actions') {
                return;
            }
            if (!currentEditing?.node) {
                if (event.column.isCellEditable(event.node)) {
                    setCurrentEditing({ node: event.node, column: event.column.getColId() });
                } else {
                    setCurrentEditing({ node: event.node, column: undefined });
                }
            } else {
                await saveRow(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {

        }
    }, [currentEditing?.node, isCancelClicked, saveRow])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'displayId',
            });
        }
    }, [currentEditing])

    const addResourceInline = useCallback(async () => {
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node.data.id) {
                        const resourceBeforeEditing = currentResourceRows?.find((resource) => (resource.id === currentEditing?.node?.data?.id));
                        if (resourceBeforeEditing) {
                            gridRef.current!.api.applyServerSideTransaction({ update: [{ ...resourceBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                        }
                    } else {
                        gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        const filters = gridRef.current!.api.getFilterModel();
        let category: ResourceCategory | undefined;
        let subCategory: ResourceSubCategory | undefined
        if (filters?.categoryDescription && filters.categoryDescription?.filter) {
            category = resourceCategories.find((cat) => (cat.description === filters.categoryDescription.filter));
        }
        if (filters?.subCategoryDescription && filters?.subCategoryDescription?.filter && category) {
            subCategory = category.resourceSubCategories?.find((sub) => (sub.description === filters?.subCategoryDescription?.filter));
        }
        const currentPage = gridRef.current!.api.paginationGetCurrentPage();
        const currentPageSize = gridRef.current!.api.paginationGetPageSize();
        let resourceRow = gridRef.current!.api.applyServerSideTransaction({
            add: [{
                id: uuidv4(),
                description: '',
                displayId: undefined,
                rate: undefined,
                basicRate: undefined,
                subCategoryId: (subCategory) ? subCategory.id : undefined,
                isComposite: false,
                resourceFactor: undefined,
                unitId: undefined,
                categoryId: (category) ? category.id : undefined,
                isSupplierRate: false,
                supplierName: undefined,
                unit: undefined,
                type: "resource",
                categoryDescription: (category) ? category.description : undefined,
                subCategoryDescription: (subCategory) ? subCategory.description : undefined,
                canDelete: true,
                masterReferenceId: undefined,
                isNew: true
            }],
            addIndex: currentPage * currentPageSize
        });
        if (resourceRow && resourceRow.add && resourceRow.add.length > 0 && resourceRow.add[0] && typeof resourceRow.add[0].rowIndex === 'number' && resourceRow.add[0].id) {
            if (resourceRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (resourceRow && resourceRow.add && resourceRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(resourceRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'displayId' });
                        }
                    }
                }))
            }
        }
    }, [confirm, currentEditing, currentResourceRows, resourceCategories])

    const handleClose = useCallback((option?: string) => {
        switch (option) {
            case 'New in form':
                addNewResourceInForm();
                break;
            case 'New inline':
            default:
                addResourceInline();
                break;
        }
    }, [addNewResourceInForm, addResourceInline])

    const onPageChanged = useCallback((event: PaginationChangedEvent<ResourceView>) => {
        if (currentEditing && currentEditing.node?.data && event.newPage) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const onPageFilterSortChanged = useCallback((event: SortChangedEvent<ResourceView> | FilterChangedEvent<ResourceView>) => {
        if (currentEditing && currentEditing.node?.data) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    return <><Box height="100%">
        {!showAdd && <><Box marginTop="10px" marginBottom="10px" alignItems="center" display="flex" justifyContent="space-between">
            <SplitButton
                disabled={editDisabledRef.current}
                buttonHeight="32px"
                options={addMenuItems}
                buttonText="New"
                buttonIcon={<AddIcon />}
                onButtonClick={handleClose}
                onMenuItemClick={handleClose} />
            {selectedRows?.length > 0 && <Button
                size="small"
                sx={{ width: "120px", height: "32px", marginLeft: "5px" }}
                color="primary"
                variant="contained"
                startIcon={<EngineeringOutlinedIcon />}
                onClick={getQuotes}>Get Quotes</Button>}
            <Box flex="1" marginLeft="20px">
                <ResourceCategoryFilters estimateId={props.estimateId} onFilterChanged={filterChanged} />
            </Box>
            <Button
                size="small"
                variant="outlined"
                sx={{ height: "32px" }}
                startIcon={<CategoryOutlinedIcon />}
                onClick={handleOpenCategoriesModal}>Categories</Button>
        </Box>
            <Box
                className="ag-theme-alpine ag-theme-bidbow inner-col"
                style={gridStyle}
                onDragOver={gridDragOver}>
                <AgGridReact<ResourceView>
                    ref={gridRef}
                    animateRows={false}
                    rowSelection="multiple"
                    editType={'fullRow'}
                    suppressScrollOnNewData={true}
                    onSelectionChanged={onSelectionChanged}
                    rowDragManaged={true}
                    suppressRowClickSelection={true}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    rowModelType={"serverSide"}
                    getRowId={getRowId}
                    pagination={true}
                    paginationPageSize={15}
                    cacheBlockSize={15}
                    paginationPageSizeSelector={[15, 25, 50, 100]}
                    onRowEditingStarted={onRowEditingStarted}
                    onRowEditingStopped={onRowEditingStopped}
                    onCellKeyDown={onCellKeyDown}
                    onCellClicked={onCellClicked}
                    onPaginationChanged={onPageChanged}
                    onSortChanged={onPageFilterSortChanged}
                    onFilterChanged={onPageFilterSortChanged}
                />
            </Box>
        </>}
        {showAdd && props.estimateId && <AddEditResource panelState={props.panelState} key={keyToUse} resetAndAddNew={resetAndAddNew} backToList={backToList} resource={editResource} estimateId={props.estimateId} />}
    </Box>
        <Modal disableRestoreFocus={true}
            open={openCategoriesModal}
            onClose={handleCloseCategoriesModal}
            aria-labelledby="categories-modal"
            aria-describedby="categories-modal-description">
            <Box className="large-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Resource Categories</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleCloseCategoriesModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <AddEditResourceCategory estimateId={props.estimateId} />
            </Box>
        </Modal>
        <Modal disableRestoreFocus={true}
            open={openFactorModal}
            onClose={handleOpenFactorModal}
            aria-labelledby="import-modal"
            aria-describedby="import-modal-description"
        >
            <Box sx={{ ...factorModalStyle }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Set factor</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="close" onClick={() => handleCloseFactorModal(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box height="100%" display="flex" alignItems="center" alignContent="center" gap="10px" justifyContent="center" flexDirection="column">
                    <Box>
                        <TextField size="small" onChange={(event) => setFactorValue(parseFloat(event.target.value))} InputProps={{ type: 'number' }}></TextField>
                    </Box>
                    <Box display="flex" gap="10px">
                        <Button variant="contained" onClick={() => handleCloseFactorModal(true)} disabled={!factorValue}>Save</Button>
                        <Button variant="contained" onClick={() => handleCloseFactorModal(false)}>Cancel</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={openSupplierModal}
            onClose={handleOpenSupplierModal}
            aria-labelledby="supplier-resources-title"
            aria-describedby="supplier-resources-description"
        >
            <Box className="small-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" color="primary">Supplier quotes for {selectedRows?.length} records</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="edit" color="primary" onClick={handleCloseSupplierModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {props?.estimateId && estimate && <SupplierQuotes resources={selectedRows} estimateId={props?.estimateId} closeModal={handleCloseSupplierModal} />}
            </Box>
        </Modal>
    </>;
}