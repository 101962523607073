import { AppendResourceDto, BaseResource, CompositeResource, CompositeResourceDto, CalculatedResource, FactorUpdateRequestDto, FilterField, FilteredResource } from 'Models/resource';
import { api } from './api'
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';


export const resourceApi = api.injectEndpoints({
  endpoints: (build) => ({
    getResourcesByFilter: build.query<Array<FilteredResource>, { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField, categoryId?: string, subCategoryId?: string }>({
      query: ({ organizationId, companyId, filterText, estimateId, field, categoryId, subCategoryId }: { organizationId: string, companyId: string, estimateId: string, filterText: string, field: FilterField, categoryId?: string, subCategoryId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/field/${field}/filter/${(filterText==='')?btoa('undefined'):btoa(filterText)}?categoryId=${categoryId}&subCategoryId=${subCategoryId}` }),
      transformResponse: (response: Array<FilteredResource>) => { return [...response] },
      providesTags: ['FilteredResources']
    }),
    getResourcesPaged: build.query<Array<FilteredResource>, { organizationId: string, companyId: string, estimateId: string, skip: number, take: number }>({
      query: ({ organizationId, companyId, skip, estimateId, take }: { organizationId: string, companyId: string, estimateId: string, skip: number, take: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/paged?skip=${skip}&take=${take}` }),
      transformResponse: (response: Array<FilteredResource>) => { return [...response] },
      providesTags: ['FilteredResources']
    }),
    getResourceByDisplayId: build.query<FilteredResource, { organizationId: string, companyId: string, estimateId: string, displayId: string }>({
      query: ({ organizationId, companyId, displayId, estimateId }: { organizationId: string, companyId: string, estimateId: string, displayId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/${displayId}` }),
      providesTags: ['ResourceByDisplayId']
    }),
    getResourceById: build.query<BaseResource, { organizationId: string, companyId: string, estimateId: string, resourceId: string }>({
      query: ({ organizationId, companyId, resourceId, estimateId }: { organizationId: string, companyId: string, estimateId: string, resourceId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/byId/${resourceId}` }),
      providesTags: ['Resource']
    }),
    getCalculatedResourceByResourceId: build.query<CalculatedResource, { organizationId: string, companyId: string, estimateId: string, resourceId: string }>({
      query: ({ organizationId, companyId, resourceId, estimateId }: { organizationId: string, companyId: string, estimateId: string, resourceId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/calculated` }),
      providesTags: ['CalculatedResource']
    }),
    getCompositeResources: build.query<Array<CompositeResource>, { organizationId: string, companyId: string, estimateId: string, resourceId: string }>({
      query: ({ organizationId, companyId, resourceId, estimateId }: { organizationId: string, companyId: string, estimateId: string, resourceId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/composites` }),
      providesTags: ['CompositeResources']
    }),
    getResourceMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string }>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/resource/max-id` }),
      providesTags: ['Resources']
    }),
    createResource: build.mutation<BaseResource, Partial<{ body: BaseResource, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Resources', 'FilteredResources'] : []),
    }),
    getResourceRows: build.mutation<{ results: Array<BaseResource>; totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/paged`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Resources'] : []),
    }),
    appendResource: build.mutation<null, Partial<{ body: AppendResourceDto, orgId: string, companyId: string, estimateId: string, resourceId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/append`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Pricing', 'CompositeResources', 'Resources', 'IndirectItemDetails', 'ItemDetails', 'Activities', 'FilteredResources', "StandardItems", "StandardItemActivityResources"]
    }),
    createCalculatedResource: build.mutation<CalculatedResource, Partial<{ body: CalculatedResource, orgId: string, companyId: string, estimateId: string, resourceId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/calculated`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'CompanyCurrency', 'CalculatedResource', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources"]
    }),
    updateCalculatedResource: build.mutation<CalculatedResource, Partial<{ body: CalculatedResource, orgId: string, companyId: string, estimateId: string, resourceId: string, calculatedResourceId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceId, calculatedResourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/calculated/${calculatedResourceId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'CompanyCurrency', 'CalculatedResource', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources"]
    }),
    updateResource: build.mutation<BaseResource, Partial<{ body: BaseResource, orgId: string, companyId: string, estimateId: string, resourceId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources", 'SupplierPackages'] : []),
    }),
    bulkUpdateFactor: build.mutation<null, Partial<{ body: FactorUpdateRequestDto, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/set-factor`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources"] : []),
    }),
    bulkRefreshCurrencies: build.mutation<null, Partial<{ orgId: string, companyId: string, estimateId: string }>>({
      query({ orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/refresh-currency`,
          method: 'PUT',
          body: {},
        }
      },
      invalidatesTags: ['SubcontractorResourcesActivities', 'Estimate', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources", 'SupplierPackages', 'SubPackages'],
    }),
    updateCompositeResource: build.mutation<CompositeResourceDto, Partial<{ body: CompositeResourceDto, orgId: string, companyId: string, estimateId: string, resourceId: string, compositeId: string }>>({
      query({ body, orgId, companyId, estimateId, resourceId, compositeId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/composite/${compositeId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'CompositeResources','Activities', 'Resources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources"] : []),
    }),
    deleteCompositeResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, resourceId: string, compositeId: string }>>({
      query({ orgId, companyId, estimateId, resourceId, compositeId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/composite/${compositeId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'CompositeResources','Activities', 'Resources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources"] : []),
    }),
    deleteResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, resourceId: string }>>({
      query({ orgId, companyId, estimateId, resourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => (result ? ['SubcontractorResourcesActivities', 'Pricing', 'Resources', 'FilteredResources'] : []),
    }),
    deleteCalculatedResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, resourceId: string, calculatedResourceId: string }>>({
      query({ orgId, companyId, estimateId, resourceId, calculatedResourceId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/resource/${resourceId}/calculated/${calculatedResourceId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => (result ? ['CompanyCurrency', 'CalculatedResource'] : []),
    }),
  })
});

export const {
  useLazyGetResourceByIdQuery,
  useGetResourceRowsMutation,
  useBulkRefreshCurrenciesMutation,
  useDeleteCalculatedResourceMutation,
  useGetCalculatedResourceByResourceIdQuery,
  useAppendResourceMutation,
  useGetResourcesByFilterQuery,
  useLazyGetResourcesPagedQuery,
  useLazyGetResourcesByFilterQuery,
  useGetResourcesPagedQuery,
  useBulkUpdateFactorMutation,
  useLazyGetResourceByDisplayIdQuery,
  useCreateResourceMutation,
  useUpdateResourceMutation,
  useGetCompositeResourcesQuery,
  useGetResourceMaxIdQuery,
  useLazyGetResourceMaxIdQuery,
  useCreateCalculatedResourceMutation,
  useUpdateCalculatedResourceMutation,
  useUpdateCompositeResourceMutation,
  useDeleteCompositeResourceMutation,
  useDeleteResourceMutation
} = resourceApi