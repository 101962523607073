export interface Price {
    id?: string;
    directItemsTotal?: number | null;
    indirectItemsTotal?: number | null;
    quantityEstimatedPrice?: number;
    estimatedQuantityPrice?: number;
    estimatedDirectItemsTotal?: number | null;
    estimatedIndirectItemsTotal?: number | null;
    quantityMarginOverride?: number | null;
    estimatedQuantityMarginOverride?: number | null;
    quantityPriceOverride?: number | null;
    estimatedQuantityPriceOverride?: string;
    quantityEstimatedPriceOverride?: string;
    estimatedQuantityEstimatedPriceOverride?: number | null;
    quantityPerAreaOverride?: number | null;
    estimatedQuantityPerAreaOverride?: number | null;
    quantityMarkupOverride?: number | null;
    estimatedQuantityMarkupOverride?: number | null;
    enablePriceOverride?: boolean;
    currentPrice?: number;
    currentEstimatedPrice?: number;
    markupDistributedPrelim?: number | null;
    markupLockedSum?: number | null;
    netDirectCost?: number | null;
    markupEstDistributedPrelim?: number | null;
    markupEstLockedSum?: number | null;
    netEstDirectCost?: number | null;
}

export interface ProjectPrice{
    directItemsTotal: number;
    indirectItemsTotal: number;
    estimatedDirectItemsTotal: number;
    estimatedIndirectItemsTotal: number;
    currentPrice: number;
    currentEstimatedPrice: number;
}

export enum CalculationOption{
    undefined = 'undefined',
    quantity = 'quantity',
    estimatedQuantity = 'estimated-quantity',
    overrideQuantity = 'override-quantity',
    overrideEstimatedQuantity = 'override-estimated-quantity'
}

export interface PriceView extends Price{
    quantityMargin?: number;
    quantityEstimatedMargin?: number;
    quantityPrice?: number;
    quantityPerArea?: number;
    quantityMarkup?: number;
    estimatedQuantityMargin?: number;
    estimatedQuantityEstimatedPrice?: number;
    estimatedQuantityPerArea?: number;
    estimatedQuantityMarkup?: number;
    currentPrice?: number;
    currentEstimatedPrice?: number;
    quantityOverridePriceMessage?: {isAbove: boolean; message: string};
    estQuantityPriceMessage?: {isAbove: boolean; message: string};
    estQuantityOverridePriceMessage?: {isAbove: boolean; message: string};
    quantityEstPriceMessage?: {isAbove: boolean; message: string};
    quantityOverrideEstPriceMessage?: {isAbove: boolean; message: string};
    estQuantityOverrideEstPriceMessage?: {isAbove: boolean; message: string};
}
