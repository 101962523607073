import { Box, useTheme } from "@mui/material";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { rounder } from "Helpers/rounder";
import { ItemListReport, ItemListReportView } from "Models/item-list-report";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import UnitCellRenderer from "./UnitCellRenderer";

export interface ItemBreakdownLevel3Props {
    estimateId: string | undefined;
    itemBreakDownReport: {
        results: Array<ItemListReport>;
        totalCount: number;
    }
}

export default function ItemBreakdownLevel3(props: ItemBreakdownLevel3Props) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<ItemListReportView>>(null);
    const [rowData, setRowData] = useState<Array<ItemListReportView>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });

    useEffect(() => {
        if (props.itemBreakDownReport && props.itemBreakDownReport.results.length>0){
            setRowData(props.itemBreakDownReport.results.map((result) => ({
                ...result,
                unit: {
                    unitDescription: result.customUnit,
                    unitId: result.unitId
                },
                id: uuidv4()
            })));
        }
    }, [props.itemBreakDownReport])

    const getBaseDefs = useCallback((): Array<ColDef<ItemListReportView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'resourceId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'activityId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'subItemId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'itemSubcontractorId',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'description',
                flex: 1,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                cellRenderer: UnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalQuantity',
                headerName: 'Quantity',
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'totalAmount',
                headerName: 'Amount',
                aggFunc: params => {
                    if (params.rowNode.level>=0){
                        return params.data.totalAmount;
                    }
                    let total = 0;
                    params.values.forEach(value => total += value);
                    return total;
                },
                width: 175,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                resizable: true,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                }
            },
        ];
    }, [colors?.gray, settings?.quantityDecimals, estimate?.CompanyCurrency?.Currency?.minorUnit])

    const [columnDefs] = useState<ColDef[]>(getBaseDefs());

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            field: 'displayId',
            resizable: true,
            headerName: 'ID',
            cellDataType: "text",
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            cellRendererParams: {
                suppressCount: true,
            }
        };
    }, [colors?.gray]);

    const getRowId = useCallback(function (params: GetRowIdParams<ItemListReportView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return <Box className="ag-theme-alpine ag-theme-bidbow" style={gridStyle}>
        <AgGridReact<ItemListReportView>
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            autoGroupColumnDef={autoGroupColumnDef}
            getRowId={getRowId}
            paginationAutoPageSize={true}
            pagination={true}
            groupDefaultExpanded={-1}
            treeData={true}
            groupIncludeTotalFooter={true}
            getDataPath={(data: any) => (data.hierarchy)}
        />
    </Box>
}