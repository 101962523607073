
import { BaseReportResource, CategorySubcategoryTotal, CategoryTotal, ReportEstimate } from 'Models/report';
import { api } from './api'
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';
import { ItemListReport } from 'Models/item-list-report';
export const reportApi = api.injectEndpoints({
  endpoints: (build) => ({
    
    getEstimatesForReport: build.query<Array<ReportEstimate>, {organizationId: string, companyId: string}>({
      query: ({organizationId, companyId}: {organizationId: string, companyId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimates` }),
      providesTags: ['ReportEstimates']
    }),
    getProjectResourceLevel3Report: build.query<Array<BaseReportResource>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel3` }),
    }),
    getProjectResourceLevel2Report: build.query<Array<CategorySubcategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel2` }),
    }),
    getProjectResourceLevel1Report: build.query<Array<CategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel1` }),
    }),
    getItemReportLevel3: build.mutation<{ results: Array<ItemListReport>; totalCount: number, categories: Array<string> }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/report/estimate/${estimateId}/itemListReportLevel3`,
          method: 'POST',
          body,
        }
      }
    }),
  })
});

export const {
  useGetItemReportLevel3Mutation,
  useGetEstimatesForReportQuery,
  useLazyGetEstimatesForReportQuery,
  useLazyGetProjectResourceLevel3ReportQuery,
  useLazyGetProjectResourceLevel2ReportQuery,
  useLazyGetProjectResourceLevel1ReportQuery
} = reportApi