import { Box, Button, useTheme } from "@mui/material";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { tokens } from "theme";
import { useCallback, useEffect, useState } from "react";
import { useLazyGetProjectPricingQuery } from "State/Services/pricing";
import { ProjectPrice as ProjectPriceTotals } from 'Models/price';
import { useGetUserDetailsQuery } from "State/Services/user";
import { rounder } from "Helpers/rounder";
import { useGetEstimateQuery } from "State/Services/estimate";
import CircularProgress from '@mui/material/CircularProgress';
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";

export interface ProjectPriceProps {
    estimateId: string | undefined;
}

export function ProjectPrice(props: ProjectPriceProps) {
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [expanded, setExpanded] = useState<boolean>(false);
    const [getProjectPricing] = useLazyGetProjectPricingQuery();
    const [price, setPrice] = useState<ProjectPriceTotals>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
            }
        } else {
            setHasViewAccess(true);
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const expand = useCallback(() => {
        setExpanded(expanded => !expanded);
    }, [])

    const getPrice = useCallback(async (useCache: boolean) => {
        try {
            if (user && props.estimateId && hasViewAccess) {
                setLoading(true);
                const price = await getProjectPricing({
                    companyId: user.companyId ?? '',
                    estimateId: props.estimateId,
                    organizationId: user.organizationId ?? ''
                }, useCache).unwrap()
                setPrice(price);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }

    }, [getProjectPricing, hasViewAccess, props.estimateId, user])

    useEffect(() => {
        getPrice(true);
    }, [getPrice, getProjectPricing, props.estimateId, user])

    return <>{hasViewAccess && <Box display="flex" height="100%" justifyContent="end">
        <Box display="flex" alignItems="center" sx={{ backgroundColor: colors.primary[300] }}>
            <Button title={`${expanded ? 'Collapse' : 'Expand'} Project price`} onClick={expand} sx={{ height: "100%", minWidth: "30px", width: "30px", maxWidth: "30px" }}>
                {!expanded && <ArrowLeftIcon sx={{ height: "20px", width: "20px", color: "white" }} />}
                {expanded && <ArrowRightIcon sx={{ height: "20px", width: "20px", color: "white" }} />}
            </Button>
        </Box>

        {expanded && <Box display="flex" width={expanded ? "auto" : "0px"} border={`1px solid ${colors.primary[300]}`} padding="10px" justifyContent="space-between">
            <Box sx={{ gridColumnGap: "15px" }} display="grid" gridTemplateColumns="repeat(3, auto 1fr)" gridTemplateRows="repeat(2, 1fr)" marginRight="10px">
                <Box>
                    Direct Cost:
                </Box>
                <Box textAlign="right">
                    {rounder(price?.directItemsTotal, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                </Box>
                <Box>
                    Indirect Cost:
                </Box>
                <Box textAlign="right">
                    {rounder(price?.indirectItemsTotal, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                </Box>
                <Box>
                    Price:
                </Box>
                <Box textAlign="right">
                    {rounder(price?.currentPrice, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                </Box>
                <Box>
                    Est Direct Cost:
                </Box>
                <Box textAlign="right">
                    {rounder(price?.estimatedDirectItemsTotal, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                </Box>
                <Box>
                    Est Indirect Cost:
                </Box>
                <Box textAlign="right">
                    {rounder(price?.estimatedIndirectItemsTotal, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                </Box>
                <Box>
                    Est Price:
                </Box>
                <Box textAlign="right">
                    {rounder(price?.currentEstimatedPrice, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                </Box>
            </Box>
            <Box display="flex" flexDirection="column" justifyContent="center" flex="0.1">
                <Button variant="contained" size="small" onClick={() => getPrice(false)}>{loading ? <CircularProgress sx={{ color: "white" }} size="1rem" /> : 'Refresh'}</Button>
            </Box>
        </Box>}
    </Box>}</>
}